import './index.css';

import {
  AnimatePresence, AnimateSharedLayout, motion, useAnimation,
} from 'framer-motion';
import React, { useState } from 'react';
import Div100vh from 'react-div-100vh';

export default function App() {
  const [openCard, setOpenCard] = useState('home');
  const controls = useAnimation();

  const changeCard = (section) => {
    controls.start({
      scale: [1, 0.5, 1],
      transition: { duration: 0.75 },
    });
    setOpenCard(section);
  };

  const cards = {
    home: (
      <>
        <h1 className="title">
          Andy Harris
        </h1>
        <p className="subtitle">
          <span>
            Co-Founder @
            {' '}
          </span>
          <a
            href="https://parallelfluidics.com/"
            target="_blank"
            rel="noreferrer"
            className="link"
            onClick={(e) => {
              e.preventDefault();
              changeCard('parallel');
            }}
          >
            Parallel Fluidics
          </a>
          <span className="subtitle">
            {' '}
            | Ex-
          </span>
          <a
            href="https://uber.com"
            className="link"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              changeCard('uber');
            }}
          >
            Uber
          </a>
        </p>
        <p className="subtitle">
          Harvard SB &apos;14, SM &apos;20, MBA &apos;20
        </p>
        <p>
          <a href="https://www.linkedin.com/in/ajrharris/" target="_blank" rel="noreferrer" style={{ margin: '0 8px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
            </svg>
          </a>
          <a href="mailto:andy@andyharris.dev" style={{ margin: '0 8px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
            </svg>
          </a>
        </p>
      </>
    ),
    parallel: (
      <>
        <a href="https://parallelfluidics.com/" target="_blank" rel="noreferrer"><img src="/parallel.png" alt="Parallel Fluidics logo" className="logo" /></a>
        <p style={{ fontStyle: 'italic' }}>January 2020-present.</p>
        <p>
          <a href="https://parallelfluidics.com/" target="_blank" rel="noreferrer">Microfluidic devices, fast.</a>
          {' '}
          Parallel Fluidics is a new manufacturing service for microfluidic chips,
          aimed at helping researchers and engineers accelerate their development cycles.
        </p>
      </>
    ),
    uber: (
      <>
        <a href="https://uber.com/" target="_blank" rel="noreferrer"><img src="/uber.png" alt="Uber logo" className="logo" /></a>
        <p style={{ fontStyle: 'italic' }}>
          Senior Software Engineer on Customer Obsession team.
          <br />
          July 2015-June 2018.
        </p>
        <p>
          Technical lead for self service support. Designed,
          {' '}
          <span style={{ textDecoration: 'line-through' }}>developed</span>
          {' '}
          hacked together MVP, and led team through development/launch of self service features,
          including Quick Fare Fixes and Driver Anytime Feedback.
        </p>
        <p>
          Technical lead for Customer Obsession team&apos;s contribution to
          {' '}
          <a href="https://eng.uber.com/cota/" target="_blank" rel="noreferrer">COTA</a>
          , a system that uses natural language processing to simplify,
          expedite, and standardize support ticket resolution.
        </p>
      </>
    ),
  };

  return (
    <Div100vh style={{ overflow: 'scroll', display: 'grid', placeItems: 'center' }}>
      <div style={{ padding: 16, maxWidth: '95vw' }}>
        <AnimateSharedLayout>
          <motion.div layout className="card" animate={controls}>
            {openCard === 'home' ? null : (
              <motion.button
                layout
                style={{ position: 'fixed', top: 16, left: 16 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.75 }}
                type="button"
                className="link"
                onClick={() => changeCard('home')}
              >
                <motion.svg layout xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                  <motion.path layout d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                </motion.svg>
              </motion.button>
            )}
            {['home', 'parallel', 'uber'].map((section) => (
              openCard === section
            && (
            <AnimatePresence>
              <Content key={section} content={cards[section]} />
            </AnimatePresence>
            )))}
          </motion.div>
        </AnimateSharedLayout>
      </div>
    </Div100vh>
  );
}

// eslint-disable-next-line react/prop-types
function Content({ content }) {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      {content}
    </motion.div>
  );
}
